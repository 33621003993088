<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';

export default {
    components: {
        Layout,
        PageHeader
    },

    mounted() {
        console.log("view web page edit drafts");
        this.getDrafts();
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId'
        ])
    },

    data() {
        return {
            title: "Web Page Editor",
            draftsObj: {},
            selectedPages: [],
            isLoading : false
        }
    },

    methods: {
        formatUtcDate(dateStr) {
            var m = new Date(dateStr);
            return m.getUTCFullYear() + "/" + (m.getUTCMonth() + 1) + "/" + m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();
        },

        onAllPageSelected(e) {
            var isSelected = e.target.checked;

            if (isSelected === true) {
                document.querySelectorAll('._item').forEach(x => { x.checked = true });
                this.selectedPages = this.items;
            } else {
                document.querySelectorAll('._item').forEach(x => { x.checked = false });
                this.selectedPages = [];
            }
        },

        onPageSelected(e, selectedModal) {
            var isSelected = e.target.checked;

            if (isSelected === true) {
                if (this.selectedPages.filter(x => x === selectedModal).length === 0) {
                    this.selectedPages.push(selectedModal);
                }
            } else {
                this.selectedPages = this.selectedPages.filter(x => x !== selectedModal);
            }

            document.querySelector('._selectAllCb').checked = this.selectedPages.length === this.draftsObj.length;
        },

        getDrafts() {
            this.isLoading = true;

            api.getWebPageEditDrafts(this.selectedWebisteId)
                .then(r => {
                    console.log('edit page drafts', r);
                    this.draftsObj = r.data;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        removeFromLive() {
            this.isLoading = true;

            let requestBody = {
                pageEdits: []
            };

            this.selectedPages.forEach(
                x => {
                    requestBody.pageEdits.push(
                        {
                            pageEditElementId: x.ammendmentId,
                            editStatus: "draft"
                        }
                    )
                }
            );

            api.updateWebPageEditorDrafts(this.selectedWebisteId, requestBody)
                .then(async r => {
                    console.log('upadted edits', r);
                    await this.getDrafts();
                    this.unselectAllPages();
                }).finallyfinally(() => {
                    this.isLoading = false;
                });
        },

        publishLive() {
            this.isLoading = true;
            let requestBody = {
                pageEdits: []
            };

            this.selectedPages.forEach(
                x => {
                    requestBody.pageEdits.push(
                        {
                            pageEditElementId: x.ammendmentId,
                            editStatus: "published"
                        }
                    )
                }
            );

            api.updateWebPageEditorDrafts(this.selectedWebisteId, requestBody)
                .then(async r => {
                    console.log('upadted edits', r);
                    await this.getDrafts();
                    this.unselectAllPages();
                }).finally(() => {
                    this.isLoading = false;
                });
        },

        unselectAllPages() {
            let inputEls = document.getElementsByTagName('input');
            inputEls.forEach(x => {
                if (x.type == 'checkbox') {
                    x.checked = false;
                }
            });
        }
    }
}
</script>
        
<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col">
                <div class="card">
                    <div class="card-header align-items-center d-flex">
                        <p class="
                                    text-uppercase
                                    fw-bold
                                    text-truncate
                                    mb-0
                                    ">
                                    Page Editor Drafts
                                </p>
                    </div>
                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">
                        <p>Select the drafts you want to publish to live.</p>
                        <table class="table align-middle">
                            <tr>
                                <th><input class="form-check-input ms-2 me-2 pt-2 pb-1 ps-1 align-items-center _selectAllCb"
                                        type="checkbox" aria-label="Select all" @click="onAllPageSelected($event)"></th>
                                <th class="ps-2">Page Url
                                </th>
                                <th class="ps-2">Change Description</th>
                                <th class="ps-2">Last Update</th>
                                <th class="ps-2">Status</th>
                            </tr>
                            <tbody v-for="(item, index) in draftsObj" :key="index">
                                <tr>
                                    <td> <input class="form-check-input mt-0 _item" type="checkbox" aria-label="Select page"
                                            @click="onPageSelected($event, item)"></td>
                                    <td>
                                        {{ item.pagePath }}
                                    </td>
                                    <td>
                                        {{ item.ammendmentDescription }}
                                    </td>
                                    <td>{{ formatUtcDate(item.lastUpdate) }}</td>
                                    <td>{{ item.status }}
                                    </td>
                                </tr>
                                <tr v-if="draftsObj.length == 0">
                                    <td colspan="4" class="text-center">No paged edits</td>
                                </tr>
                            </tbody>
                        </table>

                        <div class="row">
                            <div class="col d-flex align-items-end justify-content-end">
                                <div class="dropdown me-3 mt-3">
                                    <button class="btn btn-primary dropdown-toggle" type="button" id="dorpDownSubmit"
                                        data-bs-toggle="dropdown" aria-expanded="false">
                                        Change status
                                    </button>
                                    <ul class="dropdown-menu" aria-labelledby="dorpDownSubmit">
                                        <li><span class="dropdown-item" data-text="draft" @click="removeFromLive">Remove
                                                updates</span></li>
                                        <li><span class="dropdown-item" data-text="publish" @click="publishLive()">Publish
                                                updates</span></li>
                                    </ul>
                                </div>
                                <div v-if="isLoading">
                                    <div class="spinner-border text-primary"
                                        :style="`width: ${width}rem; height: ${height}rem;`" role="status">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- end card body-->
                    </div>
                    <!--end card-->
                </div>
            </div>
        </div>
    </Layout>
</template>